<template>
  <div class="get-free-course">
    <div class="filter">
      <div class="tip">课程也可以在“书院课程库-买断课程”列表查看</div>
      <div class="search">
        <el-input
          v-model="keyword"
          clearable
          type="text"
          maxlength="64"
          placeholder="输入您想搜索的课程"
        ></el-input>
        <div class="btn" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div class="content">
      <div v-if="courseFreeList.length" class="clearfix">
        <div
          v-for="item in courseFreeList"
          :key="item.productId"
          class="course-item"
          @click="toDetail(item.productId)"
        >
          <!-- <div class="badge">
            <img src="@/assets/shop/lubo_icon.png" alt="" />
          </div> -->
          <div class="cover">
            <img :src="item.pic" alt="" />
          </div>
          <div class="text">
            <div class="name">{{ item.name }}</div>
            <div class="info">{{ item.resCount }}节丨{{ item.timeCount }}分钟</div>
            <img src="@/assets/course/get_icon.png" alt="" class="getIcon" />
          </div>
        </div>
      </div>
      <div v-else class="empty">暂无数据</div>
    </div>
  </div>
</template>
<script>
import { getProductGivePage } from '@/api/college'
import to from 'await-to'
export default {
  name: 'CourseShop',
  data() {
    return {
      loading: false,
      courseFreeList: [],
      keyword: '',
      pager: {
        total: 0,
        size: 9999,
        current: 1,
      },
    }
  },
  mounted() {
    this.getCourseList()
  },
  methods: {
    async getCourseList(params = {}) {
      const { current, size } = this.pager
      const [res, err] = await to(getProductGivePage({ current, size, ...params }))
      if (err) return this.$message.warning(err.msg)
      this.courseFreeList = res.data.list
      this.pager.total = res.data.total
    },
    async search() {
      var params = { keyword: this.keyword }
      const { size } = this.pager
      const [res, err] = await to(getProductGivePage({ current: 1, size, ...params }))
      if (err) return this.$message.warning(err.msg)
      this.courseFreeList = res.data.list
      this.pager.total = res.data.total
      // this.getCourseList({ keyword: this.keyword })
    },
    toDetail(productId) {
      this.$router.push(`/course/shop/${productId}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.get-free-course {
  max-width: 1556px;
  .filter {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .tip {
      font-size: 14px;
      line-height: 30px;
      color: #000000;
    }
    .search {
      position: relative;
      width: 240px;
      height: 30px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #ff7b33;
      border-radius: 16px;
      ::v-deep input {
        box-sizing: border-box;
        width: 240px;
        height: 30px;
        border-radius: 16px;
        border: 0;
        background-color: #f5f5f5;
        outline: none;
        padding: 0 60px 0 10px;
      }
      .btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 30px;
        background: #ff7b33;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .content {
    min-height: calc(100vh - 200px);
    box-sizing: border-box;
    padding: 24px 0 0 24px;
    background: #ffffff;
    > div {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
    }
    ::v-deep.course-item {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      width: 282px;
      border-radius: 4px;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.06);
      margin-right: 23px;
      margin-bottom: 24px;
      transition: transform 0.3s;
      &.is-checked {
        border: 1px solid #ff7b33;
      }
      &:hover {
        transform: translateY(-5px);
      }
      .el-checkbox__label {
        padding: 0;
      }
      .el-checkbox__input {
        right: 20px;
        bottom: 20px;
        position: absolute;
      }
      .cover {
        width: 282px;
        height: 145px;
        img {
          @extend %width-height;
        }
      }
      .text {
        height: 63px;
        box-sizing: border-box;
        width: 282px;
        padding: 14px 10px 16px;
        position: relative;
        .getIcon {
          position: absolute;
          width: 61px;
          height: 63px;
          right: 0;
          bottom: 0;
        }
        .name {
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 7px;
          @extend %one-line-text;
        }
        .info {
          font-size: 12px;
          color: #666666;
          line-height: 12px;
        }
      }
      .badge {
        position: absolute;
        width: 56px;
        height: 22px;
        left: 10px;
        top: 10px;
        img {
          @extend %width-height;
        }
      }
    }
    .empty {
      text-align: center;
      font-size: 14px;
      padding-bottom: 20px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .el-pagination {
    padding-bottom: 20px;
  }
}
</style>
